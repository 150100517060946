@import url('https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --font-family: 'Exo', sans-serif;
  
  --color-dark: #2A2E2D;
  --color-grey : #404141;
  --color-light: #feeeeee3;
}

.left-border{
    border-left: 5px solid var(--color-grey);
}

.bottom-border{
  border-bottom: 4px solid var(--color-grey);
}