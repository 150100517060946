.navbar-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
}

.navbar-photo{
    max-width: 200px;
    border-radius: 100px;
    transition: 0.5s;
    cursor: pointer;
}
.navbar-photo:hover{
    transform:translateY(-2px) scale(1.01);
}

.icons{
    transition: 0.5s;
}

.icons:hover{
    transform:translateY(-5px);
}

.intro_specials{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
}

.socials{
    display: flex;
    justify-content: space-around;
    width: 60%;
    align-items: center;
    list-style: none;
}

.socials li a{
    display: flex;
}
.contact-me-btn{
    padding: 10px;
    font-weight: bold;
    font-size: 18px;
    width: 30%;
    border-radius: 10px;
    border: 1px solid var(--color-light);
    background-color: var(--color-dark);
    color: var(--color-light);
    cursor: pointer;
    transition: 0.5s;
}

.contact-me-btn:hover{

    border: none;
    outline: none;
    color: var(--color-dark);
    background-color: var(--color-light);
}

#logo{
    background-color: transparent;
    border: none;
    outline: none;
}

@media screen and (max-width:1200px)
 {
    .intro_specials{
        width: 65%;
    }
    .contact-me-btn{
        width: 40%;
    }
}

@media screen and (max-width:700px)
 {
    .navbar-photo{
        display: none;
    }
    .navbar-container{
        justify-content: center;
    }
    .intro_specials{
        width: 100%;
    }
    .socials{
        justify-content: flex-start;
    }
    .icons{
        margin-right: 1.5rem;
    }
}

@media screen and (max-width:700px)
 {

    .icons{
        margin-right: 1rem;
    }

    .contact-me-btn{
        font-size: 15px;
    }
}
